export const environment = {
  production: true,
  api_base_url: 'https://api-tv.francetvpub.fr',
  manager_api_base_url: 'https://api-manager.francetvpub.fr',
  contract_microservice_api_base_url: 'https://tv-contract-api.francetvpub.fr',
  oidc: {
    client_id: '0D97F28B1FBA6BFF',
    scope: 'openid email profile groups DistinguishedName',
    response_type: 'code',
    grant_type: 'authorization_code',
    auth_callback_path: '/login/callback',
    openid_authorize_endpoint: 'https://portailacces.francetvpub.fr/IdPOAuth2/authorize/ilexoidc',
  },
  pop_dom_microservice_base_url: "https://popdom-api.francetvpub.fr",
};
