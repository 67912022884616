import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ORGuardService implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private snackBar: MatSnackBar) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | boolean {
    this.authService.getUserInfo().subscribe(info => {
      const OUCode = this.authService.formatOu(info.DistinguishedName).toUpperCase();

      this.getAuthCode(OUCode)
        .subscribe(
          response => {
            if ('or' in response && response['or']) {
              return true;
            } else {
              this.router.navigate(['/']);
              this.snackBar.open("Vous n'êtes pas autorisé à consulter cette page.", null, {
                duration: 4000,
                verticalPosition: 'top',
              });
              return false;
            }
          },
          (error: Error) => {
            console.log(error);
            return false;
          }
        )
        .unsubscribe();
    });
  }

  getAuthCode(OUCode): Observable<any> {
    return this.authService.checkAccess(OUCode);
  }
}
