/* Contains all application constants */
export const DEFAULT_LOCALE = 'fr-FR';

// TODO: Delete when Manager Logic is implemented
export const ORTrigramList = ['vle', 'sgt', 'pga', 'gtr', 'apd'];

// Menus
export const ORMenu = [
  {
    label: 'Dashboard',
    link: '/or/dashboard',
    icon: 'assets/images/icons/tv-icons/home-icon.svg',
    isLastInSection: true,
  },
  {
    label: 'Gestion des écrans',
    link: '/or/screens',
    icon: 'assets/images/icons/tv-icons/grid-icon.svg',
    isLastInSection: false,
  },
  {
    label: 'Creation en masse',
    link: '/or/batch-create',
    icon: 'assets/images/icons/tv-icons/stack-icon.svg',
    isLastInSection: false,
  },
  {
    label: 'Mise à jour en masse',
    link: '/or/batch-update',
    icon: 'assets/images/icons/tv-icons/batch-update-icon.svg',
    isLastInSection: true,
  },
  {
    label: 'Tarifs',
    link: '/or/rates',
    icon: 'assets/images/icons/tv-icons/rate-icon.svg',
    isLastInSection: false,
  },
  {
    label: 'Alerting',
    link: '/or/notifications',
    icon: 'assets/images/icons/tv-icons/notification-icon.svg',
    isLastInSection: false,
  },
  {
    label: 'Paramétrage',
    link: '/or/settings',
    icon: 'assets/images/icons/tv-icons/settings-icon.svg',
    isLastInSection: true,
  },
];

export const OldMenu = [
  {
    label: 'TV segmentée',
    link: '/tv-adressee',
    icon: 'assets/images/icons/tv-icons/segmented-tv-icon.svg',
  },
  {
    label: 'TV linéaire',
    link: '/tv-lineaire',
    icon: 'assets/images/icons/tv-icons/linear-tv-icon.svg',
  },
  {
    label: 'Mes ressources',
    link: '/resources',
    icon: 'assets/images/icons/tv-icons/resources-icon.svg',
  },
  {
    label: 'Pop dom',
    link: '/popdom',
    icon: 'assets/images/icons/tv-icons/pop-dom.svg',
  },
  {
    label: 'Paramétrage',
    link: '/settings',
    icon: 'assets/images/icons/tv-icons/settings-icon.svg',
  },
];
export const AdvMenu = [
  {
    label: 'Encours Client',
    link: '/adv/oustanding-balance',
    icon: 'assets/images/icons/tv-icons/rate-icon.svg',
  },
];

export const CommercialMenu = [
  {
    label: 'Dashboard',
    link: '/commercial/dashboard',
    icon: 'assets/images/icons/tv-icons/home-icon.svg',
    isLastInSection: true,
  },
  {
    label: 'Liste des contrats',
    link: '/commercial/contracts',
    icon: 'assets/images/icons/tv-icons/grid-icon.svg',
    isLastInSection: false,
  },
];

export const ThemaDiffusionTimeRange = {
  minHours: 3,
  maxHours: 29,
  minMinutes: 0,
  maxMinutes: 59,
};

export const ThemaBreakCodeRange = {
  minHours: 3,
  maxHours: 27,
  minMinutes: 0,
  maxMinutes: 99,
};

export const NbcId = '2';

export const FileType = {
  grid: 1,
  gridContext: 2,
};

export const TvFileStatus = {
  abandoned: 4,
  done: 2,
};
