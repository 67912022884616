export enum BatchUpdateStep {
  Integration = '1.0',
  Transformation = '2.0',
  Correction = '3.0',
  DuplicateCheck = '3.1',
  ClosureDateCheck = '3.2',
  PeriodValidation = '4.0',
  DataPreparation = '5.0',
  BroadcastTimeAutoChange = '5.1',
  Validation = '6.0',
  ReportGeneration = '6.1',
  FlashGeneration = '6.2',
  BreakIdOrder = '6.5',
  DataBaseUpdate = '7.0',
  Cancel = '0.0',
  Success = '8.0',
  PartialSuccess = '8.1',
  Error = '9.0',
}

export enum BatchUpdateStepName {
  Integration = 'INTEGRATION',
  Transformation = 'TRANSFORMATION',
  Correction = 'CORRECTION',
  DuplicateCheck = 'DUPLICATE_CHECK',
  ClosureDateCheck = 'CLOSURE_DATE_CHECK',
  DataPreparation = 'DATA_PREPARATION',
  BroadcastTimeAutoChange = 'BROADCAST_TIME_AUTO_CHANGE',
  Validation = 'VALIDATION',
  ReportGeneration = 'REPORT_GENERATION',
  FlashGeneration = 'FLASH_GENERATION',
  BreakIdOrder = "Vérification d'ordre des break ids",
  DataBaseUpdate = 'DATABASE_UPDATE',
}

export enum BatchUpdateSubStep {
  InProgress = 1,
  Finished = 2,
  PartialFinished = 3,
  Error = 0,
}

export const FinishedSteps = [BatchUpdateStep.Cancel, BatchUpdateStep.Success, BatchUpdateStep.Error];

export function confirmBatchUpdateCompletion(stepId: string): boolean {
  const isPresentInFinishedSteps = FinishedSteps.find(finishedStep => finishedStep === stepId);
  return !!isPresentInFinishedSteps;
}

export function getNextStepLabel(stepName: string) {
  switch (stepName) {
    case BatchUpdateStep.Integration:
      return BatchUpdateStepName.Transformation;
    case BatchUpdateStep.Transformation:
      return BatchUpdateStepName.Correction;
    case BatchUpdateStep.Correction:
      return BatchUpdateStepName.DuplicateCheck;
    case BatchUpdateStep.DuplicateCheck:
      return BatchUpdateStepName.ClosureDateCheck;
    case BatchUpdateStep.ClosureDateCheck:
      return BatchUpdateStepName.DataPreparation;
    case BatchUpdateStep.DataPreparation:
      return BatchUpdateStepName.BroadcastTimeAutoChange;
    case BatchUpdateStep.BroadcastTimeAutoChange:
      return BatchUpdateStepName.Validation;
    case BatchUpdateStep.Validation:
      return BatchUpdateStepName.ReportGeneration;
    case BatchUpdateStep.ReportGeneration:
      return BatchUpdateStepName.FlashGeneration;
    case BatchUpdateStep.FlashGeneration:
      return BatchUpdateStepName.BreakIdOrder;
    case BatchUpdateStep.BreakIdOrder:
      return BatchUpdateStepName.DataBaseUpdate;
  }
}

export function getNextStepId(stepName: string): string {
  switch (stepName) {
    case BatchUpdateStep.Integration:
      return BatchUpdateStep.Transformation;
    case BatchUpdateStep.Transformation:
      return BatchUpdateStep.Correction;
    case BatchUpdateStep.Correction:
      return BatchUpdateStep.DuplicateCheck;
    case BatchUpdateStep.DuplicateCheck:
      return BatchUpdateStep.ClosureDateCheck;
    case BatchUpdateStep.ClosureDateCheck:
      return BatchUpdateStep.DataPreparation;
    case BatchUpdateStep.DataPreparation:
      return BatchUpdateStep.BroadcastTimeAutoChange;
    case BatchUpdateStep.BroadcastTimeAutoChange:
      return BatchUpdateStep.Validation;
    case BatchUpdateStep.Validation:
      return BatchUpdateStep.ReportGeneration;
    case BatchUpdateStep.ReportGeneration:
      return BatchUpdateStep.FlashGeneration;
    case BatchUpdateStep.FlashGeneration:
      return BatchUpdateStep.BreakIdOrder;
    case BatchUpdateStep.BreakIdOrder:
      return BatchUpdateStep.DataBaseUpdate;
    default:
      return '';
  }
}

export interface JsonBatchUpdateStatus {
  fileName: string;
  id: number;
  information?: string;
  integrationDate: Date;
  _embedded: {
    channel: {
      id: number;
      channelCode: number;
      name: string;
    };
    step: {
      id: string;
      label: string;
    };
    processStep: {
      id: number;
      label: string;
    };
  };
}

export class BatchUpdateStatus {
  fileName: string;
  id: number;
  information: string = '';
  integrationDate: Date;
  stepId: string;
  stepLabel: string;
  subStepId: number;
  subStepLabel: string;

  constructor(jsonBatchUpdateStatus: JsonBatchUpdateStatus) {
    this.fileName = jsonBatchUpdateStatus.fileName;
    this.id = jsonBatchUpdateStatus.id;
    this.stepId = jsonBatchUpdateStatus._embedded.step.id;
    this.stepLabel = jsonBatchUpdateStatus._embedded.step.label;
    this.subStepId = Number(jsonBatchUpdateStatus._embedded.processStep.id);
    this.subStepLabel = jsonBatchUpdateStatus._embedded.processStep.label;
  }
}
