import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject, merge } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @Input() screenFilterForm: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {}
  private componentDestroyed$: Subject<void> = new Subject();

  dateRangeForm = this.fb.group({
    startDate: ['', [Validators.required]],
    endDate: ['', [Validators.required]]
  });

  ngOnInit(): void {
    this.initValueChange();
  }

  public initValueChange() {
    merge(
      this.dateRangeForm.get("startDate").valueChanges,
      this.dateRangeForm.get("endDate").valueChanges,
    )
    .pipe(
      debounceTime(500),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(
      () => {
        this.screenFilterForm.get('startDate').setValue(moment(this.dateRangeForm.value.startDate, 'DD/MM/YYYY'));
        this.screenFilterForm.get('endDate').setValue(moment(this.dateRangeForm.value.endDate, 'DD/MM/YYYY'));
      },
      (error: Error) => {
        console.error(error);
      }
    );
  }
  
}
