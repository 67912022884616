import { createDateFrom, getOracleDateWithoutMinutesFromString } from '@shared/utils/transform-date-funcs/transform-date-funcs';

export enum ValidationDataLineStatus {
  New = 'new',
  Deleted = 'deleted',
  Error = 'error',
  Available = 'available',
}

export interface JsonBathUpdateValidationData {
  id: number;
  idBatchUpdate: number;
  idTvChannel: number;
  status: string | null;
  breakCode: string;
  broadcastDate: any;
  broadcastTimeOld: string;
  broadcastTimeNew: string;
  durationOld: number;
  durationNew: string;
  price: number;
  captifPrice: number | null;
  revenue: number;
  timeTakenInSlot: number;
  timeAllowedPerSlot: number;
  slotStartTime: string | null;
  slotEndTime: string | null;
  idSlot: number | null;
  percentageDurationSold: number;
  titleBefore: string;
  titleAfter: string;
  isUserApproved: any;
  timeUpdAutoChanged: any;
  isDuplicate: any;
}

export interface JsonDayToValidate {
  broadcastDate: string;
  nbTotalScreens: string;
  nbNewScreens: string;
  nbErrorScreens: string;
  nbDeletedScreens: string;
  isUserApproved: any;
  isIgnored: any;
}

export interface JsonBatchUpdateValidationSummary {
  startDate: string;
  minNotIgnoredDate: string;
  endDate: string;
  nbNewScreens: string;
  nbDeletedScreens: string;
  nbErrorScreens: string;
  nbTotalScreens: string;
}

export class BatchUpdateValidationData {
  id: number;
  idBatchUpdate: number;
  idTvChannel: number;
  status: string | null;
  breakCode: string;
  broadcastDate: Date;
  broadcastTimeOld: string;
  broadcastTimeNew: string;
  durationOld: number;
  durationNew: string;
  price: number;
  captifPrice: number | null = null;
  revenue: number;
  timeTakenInSlot: number;
  timeAllowedPerSlot: number;
  slotStartTime: string | null;
  slotEndTime: string | null;
  idSlot: number | null;
  percentageDurationSold: number;
  titleBefore: string;
  titleAfter: string;
  isUserApproved: boolean;
  timeUpdAutoChanged: boolean;
  isDuplicate: boolean;

  constructor(jsonBatchUpdateValidationData: JsonBathUpdateValidationData) {
    this.id = jsonBatchUpdateValidationData.id;
    this.idBatchUpdate = jsonBatchUpdateValidationData.idBatchUpdate;
    this.idTvChannel = jsonBatchUpdateValidationData.idTvChannel;
    this.status = jsonBatchUpdateValidationData.status ? jsonBatchUpdateValidationData.status.toLowerCase() : null;
    this.breakCode = jsonBatchUpdateValidationData.breakCode;
    this.broadcastDate = createDateFrom(
      jsonBatchUpdateValidationData.broadcastDate && jsonBatchUpdateValidationData.broadcastDate.date
        ? jsonBatchUpdateValidationData.broadcastDate.date
        : jsonBatchUpdateValidationData.broadcastDate
    );
    this.broadcastTimeOld = jsonBatchUpdateValidationData.broadcastTimeOld;
    this.broadcastTimeNew = jsonBatchUpdateValidationData.broadcastTimeNew;
    this.durationOld = jsonBatchUpdateValidationData.durationOld;
    this.durationNew = jsonBatchUpdateValidationData.durationNew;
    this.price = jsonBatchUpdateValidationData.price;
    this.captifPrice = jsonBatchUpdateValidationData.captifPrice;
    this.revenue = jsonBatchUpdateValidationData.revenue;
    this.timeTakenInSlot = Number(jsonBatchUpdateValidationData.timeTakenInSlot);
    this.timeAllowedPerSlot = Number(jsonBatchUpdateValidationData.timeAllowedPerSlot);
    this.slotStartTime = jsonBatchUpdateValidationData.slotStartTime;
    this.slotEndTime = jsonBatchUpdateValidationData.slotEndTime;
    this.idSlot = jsonBatchUpdateValidationData.idSlot;
    this.percentageDurationSold = jsonBatchUpdateValidationData.percentageDurationSold;
    this.titleBefore = jsonBatchUpdateValidationData.titleBefore;
    this.titleAfter = jsonBatchUpdateValidationData.titleAfter;
    this.isUserApproved = jsonBatchUpdateValidationData.isUserApproved == 1;
    this.timeUpdAutoChanged = jsonBatchUpdateValidationData.timeUpdAutoChanged == 1;
    this.isDuplicate = jsonBatchUpdateValidationData.isDuplicate == 1;
  }

  static copy(dataToCopy: BatchUpdateValidationData): BatchUpdateValidationData {
    const valData = new BatchUpdateValidationData({
      id: dataToCopy.id,
      idBatchUpdate: dataToCopy.idBatchUpdate,
      idTvChannel: dataToCopy.idTvChannel,
      status: dataToCopy.status,
      breakCode: dataToCopy.breakCode,
      broadcastDate: null,
      broadcastTimeOld: dataToCopy.broadcastTimeOld,
      broadcastTimeNew: dataToCopy.broadcastTimeNew,
      durationOld: dataToCopy.durationOld,
      durationNew: dataToCopy.durationNew,
      price: dataToCopy.price,
      captifPrice: dataToCopy.captifPrice,
      revenue: dataToCopy.revenue,
      timeTakenInSlot: dataToCopy.timeTakenInSlot,
      timeAllowedPerSlot: dataToCopy.timeAllowedPerSlot,
      slotStartTime: dataToCopy.slotStartTime,
      slotEndTime: dataToCopy.slotEndTime,
      idSlot: dataToCopy.idSlot,
      percentageDurationSold: dataToCopy.percentageDurationSold,
      titleBefore: dataToCopy.titleBefore,
      titleAfter: dataToCopy.titleAfter,
      isUserApproved: dataToCopy.isUserApproved,
      timeUpdAutoChanged: dataToCopy.timeUpdAutoChanged,
      isDuplicate: dataToCopy.isDuplicate,
    });
    if (dataToCopy.broadcastDate) {
      valData.broadcastDate = new Date(dataToCopy.broadcastDate);
    }
    return valData;
  }
}

export class DayToValidate {
  broadcastDateOracleString: string;
  broadcastDate: Date;
  nbTotalScreens: number;
  nbNewScreens: number;
  nbErrorScreens: number;
  nbDeletedScreens: number;
  isUserApproved: boolean;
  isIgnored: boolean = false;

  constructor(jsonDayToValidate: JsonDayToValidate) {
    this.broadcastDateOracleString = jsonDayToValidate.broadcastDate.substring(0, 10);
    this.broadcastDate = createDateFrom(jsonDayToValidate.broadcastDate);
    this.nbTotalScreens = jsonDayToValidate.nbTotalScreens ? Number(jsonDayToValidate.nbTotalScreens) : 0;
    this.nbNewScreens = jsonDayToValidate.nbNewScreens ? Number(jsonDayToValidate.nbNewScreens) : 0;
    this.nbErrorScreens = jsonDayToValidate.nbErrorScreens ? Number(jsonDayToValidate.nbErrorScreens) : 0;
    this.nbDeletedScreens = jsonDayToValidate.nbDeletedScreens ? Number(jsonDayToValidate.nbDeletedScreens) : 0;
    this.isUserApproved = jsonDayToValidate.isUserApproved == 1;
    this.isIgnored = jsonDayToValidate.isIgnored == 1;
  }
}

export class BatchUpdateValidationSummary {
  startDate: Date;
  minNotIgnoredDate: string;
  endDate: Date;
  nbNewScreens: string;
  nbDeletedScreens: string;
  nbErrorScreens: string;
  nbTotalScreens: string;
  daysToValidate: DayToValidate[] = [];

  constructor(jsonBatchUpdateValidationSummary: JsonBatchUpdateValidationSummary) {
    this.startDate = createDateFrom(jsonBatchUpdateValidationSummary.startDate);
    this.minNotIgnoredDate = getOracleDateWithoutMinutesFromString(jsonBatchUpdateValidationSummary.minNotIgnoredDate);
    this.endDate = createDateFrom(jsonBatchUpdateValidationSummary.endDate);
    this.nbNewScreens = jsonBatchUpdateValidationSummary.nbNewScreens;
    this.nbDeletedScreens = jsonBatchUpdateValidationSummary.nbDeletedScreens;
    this.nbErrorScreens = jsonBatchUpdateValidationSummary.nbErrorScreens;
    this.nbTotalScreens = jsonBatchUpdateValidationSummary.nbTotalScreens;
  }
}
