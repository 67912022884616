import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommercialMenu, OldMenu, ORMenu } from '@app/app.constants';

export interface MenuItem {
  label: string;
  link: string;
}

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnChanges, OnInit {
  @Input() identity: any;
  @Input() menuItems: MenuItem[] = [];
  @Input() isDev = false;
  @Output() logout: EventEmitter<void> = new EventEmitter();

  public name = '';
  public menuIsClosed = false;

  devMenuOptions = [
    {
      label: 'Menu Or',
      value: ORMenu,
    },
    {
      label: 'Menu Planning',
      value: OldMenu,
    },
    {
      label: 'Menu Commercial',
      value: CommercialMenu,
    },
  ];
  currentMenuOption = new FormControl(this.devMenuOptions[0]);

  constructor(private router: Router) {}

  ngOnChanges() {
    if (!this.menuItems) {
      return;
    }

    this.getCurrentDevMenuOptionFromLink();

    this.currentMenuOption.valueChanges.subscribe(menuOption => {
      this.menuItems = menuOption.value;
    });
  }

  ngOnInit() {
    if (!this.menuItems) {
      return;
    }

    if (!this.isDev && this.menuItems && this.menuItems[0] && this.menuItems[0].link) {
      this.router.navigate([this.menuItems[0].link]);
    }

    if (this.identity && this.identity.given_name && this.identity.family_name) {
      this.name = this.identity.given_name.substring(0, 1) + this.identity.family_name.substring(0, 1);
    }
  }

  getCurrentDevMenuOptionFromLink() {
    const currentDevOption = this.devMenuOptions.find(devMenuOption => {
      return devMenuOption.value.find(devMenOp => devMenOp.link === this.menuItems[0].link);
    });

    if (currentDevOption && currentDevOption.label) {
      const index = this.devMenuOptions.findIndex(devOp => devOp.label === currentDevOption.label);
      this.currentMenuOption.setValue(this.devMenuOptions[index]);
    }
  }

  onClickLogout() {
    this.logout.emit();
  }

  redirectToHome() {
    this.router.navigate(['/']);
  }

  onCloseMenuButtonClick() {
    this.menuIsClosed = !this.menuIsClosed;
  }
}
